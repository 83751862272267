/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import api from '../../../services/API';
import './styles.css';
import { Link } from 'react-router-dom';

export default function PremiosLoja(props) {
  const [premios, setPremios] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [categoriasSelecionadas, setCategoriasSelecionadas] = useState([]);
  const [busca, setBusca] = useState('');
  const [buscando, setBuscando] = useState(false);
  const [buscaSaldo, setBuscaSaldo] = useState(false);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [totalPremiosNaPagina, setTotalPremiosNaPagina] = useState(0); 
  const itensPorPagina = 12;

  const buscarPremios = async () => {
    const resultado = await api.get(
      `/api/premios?ativo=1${
        props.tipo ? `&tipo=${props.tipo}` : ''
      }${`&busca=${busca}`}${`&categoria=${categoriasSelecionadas.join(
        ','
      )}`}${`&buscaSaldo=${buscaSaldo}`}&pagina=${paginaAtual}&itensPorPagina=${itensPorPagina}`
    );
    
    if (resultado) {
      const premiosAtivos = resultado.data.premios.filter((p) => p.ativo === 1 && Number(p.pontos) > 0);
      setPremios(premiosAtivos);
      setTotalPremiosNaPagina(premiosAtivos.length); 
    }
  };

  const buscarCategorias = async () => {
    const resultado = await api.get('/api/categorias');

    if (resultado) {
      setCategorias(resultado.data.categorias);
    }
  };

  function gerenciarCategoriaSaldo(e) {
    if (busca) {
      setBusca('');
    }

    const targetLi = e.target;
    if (targetLi.classList.contains('active')) {
      targetLi.classList.remove('active');
      setBuscaSaldo(false);
    } else {
      targetLi.classList.add('active');
      setBuscaSaldo(true);
    }
  }

  const gerenciarCategorias = async (e) => {
    if (busca) {
      setBusca('');
    }

    const categoria = e.target.closest('li').textContent.trim();
    const targetLi = e.target.closest('li');

    if (e.detail === 2) {
      document.querySelectorAll('li.categoria').forEach(li => li.classList.remove('active'));
      targetLi.classList.add('active');
      setCategoriasSelecionadas([categoria]);
    } else {
      if (targetLi.classList.contains('active')) {
        targetLi.classList.remove('active');
        setCategoriasSelecionadas(prevCategorias => prevCategorias.filter(c => c !== categoria));
      } else {
        targetLi.classList.add('active');
        setCategoriasSelecionadas(prevCategorias => [...prevCategorias, categoria]);
      }
    }
    setPaginaAtual(1);
  };

  function limparFiltros() {
    document
      .querySelectorAll('li')
      .forEach((li) => li.classList.remove('active'));

    setBuscaSaldo(false);
    setCategoriasSelecionadas([]);
  }

  function handleEnter(e) {
    if (e.key === 'Enter') {
      buscaComTexto();
    }
  }

  function buscaComTexto() {
    if (categoriasSelecionadas.length > 0) {
      limparFiltros();
    }

    buscarPremios();
  }

  useEffect(() => {
    document.title = 'American Burrs - Loja';
    buscarPremios();
    buscarCategorias();
  }, [categoriasSelecionadas, buscaSaldo, paginaAtual]);

  const proximaPagina = () => {
    if (totalPremiosNaPagina === itensPorPagina) {
      setPaginaAtual(prev => prev + 1);
    }
  };

  const paginaAnterior = () => {
    setPaginaAtual(prev => Math.max(prev - 1, 1));
  };

  return (
    <Container className="mb-5">
      <Row>
        <Col xs={12} md={4} lg={3}>
          <div className="mt-3">
            <h5>CATEGORIAS</h5>
            <hr className="hr-categoria" />
          </div>
          <Row className="sticky">
            <Col>
              <ul className="list-unstyled">
                {categorias.map((categoria) => (
                  <li
                    className="categoria"
                    onClick={gerenciarCategorias}
                    key={categoria}
                  >
                    <span>
                      {categoriasSelecionadas.indexOf(categoria) === -1 ? (
                        <i className="far fa-square mr-3"></i>
                      ) : (
                        <i className="far fa-check-square mr-3"></i>
                      )}
                      {categoria}
                    </span>
                  </li>
                ))}
              </ul>
              <div className="limpar-filtros">
                <i className="fas fa-trash mr-3"></i>
                <button className="btn-limpo" onClick={limparFiltros}>
                  Limpar filtros
                </button>
                {(categoriasSelecionadas.length > 0 || buscaSaldo) && (
                  <span className="ml-2 badge badge-primary">Filtros ativos</span>
                )}
              </div>
            </Col>
          </Row>
        </Col>

        <Col xs={12} md={8} lg={9}>
          <div>
            <div className="d-flex flex-row justify-content-between align-items-center mb-0 pb-0">
              <h5>LOJA</h5>
              <div>
                <input
                  value={busca}
                  onChange={(e) => setBusca(e.target.value)}
                  onKeyUp={handleEnter}
                  className="input-busca"
                  placeholder="O que deseja resgatar?"
                />
                <button
                  className="btn-busca mt-0"
                  type="submit"
                  onClick={buscaComTexto}
                >
                  <i className="fas fa-search"></i>
                </button>
              </div>
            </div>
            <hr className="hr-loja" />
          </div>

          <Row>
            {premios.map((premio) => {
              return (
                <Col
                  key={premio.codigo}
                  id={premio.codigo}
                  name="premio"
                  xs={12}
                  md={4}
                  lg={3}
                  className="mt-5"
                >
                  <div className="d-flex flex-column justify-content-center align-items-center text-center">
                    <Link to={`/premio/${premio.codigo}`}>
                      <img
                        alt={premio.nome}
                        className="w-100"
                        src={premio.diretorioFoto}
                      ></img>
                    </Link>

                    <span className="card-premio-nome">{premio.nome}</span>
                    <span>
                      <b>
                        {Number(premio.pontos).toLocaleString('pt-BR')} pontos
                      </b>
                    </span>
                    <Link to={`/premio/${premio.codigo}`}>
                      <button className="btn-resgatar mt-1">RESGATAR</button>
                    </Link>
                  </div>
                </Col>
              );
            })}
          </Row>
          
          <div className="d-flex justify-content-center mt-4">
            <button 
              onClick={paginaAnterior} 
              disabled={paginaAtual === 1} 
              className="btn mr-2"
              style={{
                backgroundColor: '#ff0000',
                color: 'white',
                border: 'none',
                opacity: paginaAtual === 1 ? 0.5 : 1
              }}
              aria-label="Página Anterior"
            >
              <i className="fas fa-chevron-left"></i>
            </button>
            <span className="align-self-center mx-2">Página {paginaAtual}</span>
            <button 
              onClick={proximaPagina} 
              disabled={totalPremiosNaPagina < itensPorPagina} // Desabilita se a quantidade de prêmios for menor que os itens por página
              className="btn ml-2"
              style={{
                backgroundColor: '#ff0000',
                color: 'white',
                border: 'none',
                opacity: totalPremiosNaPagina < itensPorPagina ? 0.5 : 1
              }}
              aria-label="Próxima Página"
            >
              <i className="fas fa-chevron-right"></i>
            </button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
