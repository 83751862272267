import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container, Row, Col } from 'react-bootstrap';
import api from '../../../services/API';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import sortFunc from '../../../utils/ordernarColunaInt';
import moment from 'moment-timezone';
import { CSVLink } from 'react-csv';
import ModalEstoque from '../ModalEstoque/ModalEstoque';
import { useSelector } from 'react-redux';

const ExportCSV = (props) => {
  return (
    <div>
      <CSVLink
        data={props.data}
        separator={';'}
        filename={`ComprasPontuacao${moment().format('YYYYMMDDHHmmss')}.csv`}
        headers={[
          {
            key: 'rede',
            label: 'Rede',
          },
          {
            key: 'codProduto',
            label: 'Cód. American Burrs',
          },
          {
            key: 'produto',
            label: 'Produto',
          },
          {
            key: 'dataUltimoPedido',
            label: 'Data Último Pedido',
          },
          {
            key: 'qtdUltimoPedido',
            label: 'Qtd. Último Pedido',
          },
          {
            key: 'dataPenultimoPedido',
            label: 'Data Penúltimo Pedido',
          },
          {
            key: 'qtdPenultimoPedido',
            label: 'Qtd. Penúltimo Pedido',
          },
          {
            key: 'qtdVendida',
            label: 'Qtd. Vendida (Últimos 2 Pedidos)',
          },
          {
            key: 'qtdPontuada',
            label: 'Qtd. Pontuada',
          },
          {
            key: 'saldoEstoqueRegional',
            label: 'Estoque Regional',
          },
          {
            key: 'saldoEstoque',
            label: 'Saldo em Estoque',
          },
        ]}
      >
        <button className="btn-primario-slim ml-2">
          <i className="fa fa-file-download px-2"></i>
        </button>
      </CSVLink>
    </div>
  );
};

const { SearchBar } = Search;

const meses = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

export default function ComprasPontuacao() {
  const usuario = useSelector((state) => {
    return state.usuario;
  });

  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
      hidden: true,
      formatter: (celula, valor) => {
        return `${valor.rede}${valor.codProduto}`;
      },
    },
    {
      dataField: 'rede',
      text: 'Rede',
      sort: true,
    },
    {
      dataField: 'codProduto',
      text: 'Cód. American Burrs',
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'produto',
      text: 'Produto',
      sort: true,
    },
    {
      dataField: 'dataUltimoPedido',
      text: 'Data Último Pedido',
      sort: true,
      formatter: (celula, valor) => {
        return valor.dataUltimoPedido
          ? moment(valor.dataUltimoPedido).format('DD/MM/YYYY')
          : '-';
      },
    },
    {
      dataField: 'qtdUltimoPedido',
      text: 'Qtd. Último Pedido',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.qtdUltimoPedido).toLocaleString('pt-BR');
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'dataPenultimoPedido',
      text: 'Data Penúltimo Pedido',
      sort: true,
      formatter: (celula, valor) => {
        return valor.dataPenultimoPedido
          ? moment(valor.dataPenultimoPedido).format('DD/MM/YYYY')
          : '-';
      },
    },
    {
      dataField: 'qtdPenultimoPedido',
      text: 'Qtd. Penúltimo Pedido',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.qtdPenultimoPedido).toLocaleString('pt-BR');
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'qtdVendida',
      text: 'Qtd. Vendida (Últimos 2 Pedidos)',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.qtdVendida).toLocaleString('pt-BR');
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'qtdPontuada',
      text: 'Qtd. Pontuada',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.qtdPontuada).toLocaleString('pt-BR');
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'saldoEstoqueRegional',
      text: 'Estoque Regional',
      sort: true,
      formatter: (celula, valor) => {
        if (!valor.saldoEstoqueRegional) {
          return '-';
        }
        return Number(valor.saldoEstoqueRegional) < 0 ? (
          <span className="f-red">
            {Number(valor.saldoEstoqueRegional).toLocaleString('pt-BR')}
          </span>
        ) : (
          <span>
            {Number(valor.saldoEstoqueRegional).toLocaleString('pt-BR')}
          </span>
        );
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'saldoEstoque',
      text: 'Saldo em Estoque',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.saldoEstoque) < 0 ? (
          <span className="f-red">
            {Number(valor.saldoEstoque).toLocaleString('pt-BR')}
          </span>
        ) : (
          <span>{Number(valor.saldoEstoque).toLocaleString('pt-BR')}</span>
        );
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'editarEstoque',
      isDummyField: true,
      text: 'Inserir Estoque',
      sort: true,
      hidden: [1, 2].includes(usuario.permissao) ? false : true ? false : true,
      formatter: (celula, valor) => (
        <button
          className="btn-limpo f-12 p-1"
          onClick={() => {
            abrirModalEstoque(
              valor.rede,
              valor.codProduto,
              valor.id,
              valor.qtdPontuada,
              valor.qtdUltimoPedido,
              valor.qtdPenultimoPedido
            );
          }}
        >
          <i className="fas fa-edit"></i>
        </button>
      ),
    },
  ];

  const defaultSorted = [
    {
      dataField: 'rede',
      order: 'asc',
    },
  ];

  useEffect(() => {
    buscarRedes();
  }, []);

  const buscarRedes = async () => {
    const resultado = await api.get('/api/redes?ativo=1');
    if (resultado) {
      setRedesSelect(resultado.data.redes);
    }
  };

  const [dataFormated, setDataFormated] = useState([]);
  const [anoSelecionado, setAnoSelecionado] = useState(moment().year());
  const [mesSelecionado, setMesSelecionado] = useState(moment().month());
  const [data, setData] = useState([]);
  const [redeSelecionada, setRedeSelecionada] = useState('');
  const [redesSelect, setRedesSelect] = useState([]);
  const [buscando, setBuscando] = useState(false);

  const [id, setId] = useState(0);
  const [qtdPontuada, setQtdPontuada] = useState(0);
  const [qtdUltimoPedido, setQtdUltimoPedido] = useState(0);
  const [qtdPenultimoPedido, setQtdPenultimoPedido] = useState(0);

  const [rede, setRede] = useState('');
  const [codProduto, setCodProduto] = useState('');
  const [mostrarModalEstoque, setMostrarModalEstoque] = useState(false);

  const abrirModalEstoque = (
    rede,
    codProduto,
    id,
    qtdPontuada,
    qtdUltimoPedido,
    qtdPenultimoPedido
  ) => {
    setId(id);
    setCodProduto(codProduto);
    setRede(rede);
    setQtdPontuada(qtdPontuada);
    setQtdUltimoPedido(qtdUltimoPedido);
    setQtdPenultimoPedido(qtdPenultimoPedido);

    setMostrarModalEstoque(true);
  };

  const fecharModalEstoque = () => {
    setMostrarModalEstoque(false);
    setRede('');
    setCodProduto('');
  };

  const options = {
    sizePerPage: 10,
    showTotal: true,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
      {
        text: 'Todos',
        value: data.length,
      },
    ],
    paginationTotalRenderer: (from, to, size) =>
      size > 0 ? (
        <span className="react-bootstrap-table-pagination-total px-2">
          {from}-{to} de {size} resultados
        </span>
      ) : (
        <span className="react-bootstrap-table-pagination-total px-2">
          Nenhum resultado
        </span>
      ),
  };

  useEffect(() => {
    if (buscando) {
      setData([]);
    }
  }, [buscando]);

  useEffect(() => {
    if (data) {
      setDataFormated(
        data.map((d) => ({
          rede: d.rede,
          produto: d.produto,
          codProduto: d.codProduto,
          dataUltimoPedido: d.dataUltimoPedido
            ? moment(d.dataUltimoPedido).format('DD/MM/YYYY')
            : '',
          qtdUltimoPedido: Number(d.qtdUltimoPedido).toLocaleString('pt-BR'),
          dataPenultimoPedido: d.dataPenultimoPedido
            ? moment(d.dataPenultimoPedido).format('DD/MM/YYYY')
            : '',
          qtdPenultimoPedido: Number(d.qtdPenultimoPedido).toLocaleString(
            'pt-BR'
          ),
          qtdVendida: Number(d.qtdVendida).toLocaleString('pt-BR'),
          qtdPontuada: Number(d.qtdPontuada).toLocaleString('pt-BR'),
          saldoEstoque: Number(d.saldoEstoque).toLocaleString('pt-BR'),
          saldoEstoqueRegional: Number(d.saldoEstoqueRegional).toLocaleString(
            'pt-BR'
          ),
        }))
      );
    }
  }, [data]);

  const buscarComprasPontuacao = async () => {
    setBuscando(true);
    const resultado = await api.get(
      `/api/compras-pontuacao?rede=${redeSelecionada}&ano=${anoSelecionado}&mes=${(
        Number(mesSelecionado) + 1
      )
        .toString()
        .padStart(2, '0')}`
    );
    if (resultado) {
      setData(resultado.data.comprasPontuacao);
    }
    setBuscando(false);
  };

  const atualizarEstoque = async () => {
    buscarComprasPontuacao();
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Compras x Pontuação</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <Row>
        <Col xs={12} sm={6} md={4} lg={3} className="d-flex flex-column">
          <label htmlFor="redeSelecionada">Selecione uma Rede</label>
          <select
            className="input-select mb-3"
            name="redeSelecionada"
            id="redeSelecionada"
            onChange={(e) => setRedeSelecionada(e.target.value)}
            defaultValue=""
            placeholder=""
          >
            <option value="">Todas</option>
            {redesSelect.map((rede) => (
              <option value={rede.nome} key={rede.nome}>
                {rede.nome}
              </option>
            ))}
          </select>
        </Col>
        {/* <Col xs={12} sm={3} md={3} lg={2} className="d-flex flex-column">
          <label htmlFor="anoSelecionado">Ano</label>
          <select
            className="input-select mb-3"
            name="anoSelecionado"
            id="anoSelecionado"
            onChange={(e) => setAnoSelecionado(e.target.value)}
            defaultValue={anoSelecionado}
          >
            {(() => {
              let anosSelect = [];
              for (let l = 2020; l <= moment().year(); l++) {
                anosSelect.push(<option value={l}>{l}</option>);
              }
              return anosSelect;
            })()}
          </select>
        </Col>
        <Col xs={12} sm={3} md={3} lg={2} className="d-flex flex-column">
          <label htmlFor="mesSelecionado">Mês</label>
          <select
            className="input-select mb-3"
            name="mesSelecionado"
            id="mesSelecionado"
            onChange={(e) => setMesSelecionado(e.target.value)}
            defaultValue={mesSelecionado}
          >
            {(() => {
              let mesesSelect = [];
              if (anoSelecionado === 2020) {
                for (var m = 6; m <= moment().month(); m++) {
                  mesesSelect.push(<option value={m}>{meses[m]}</option>);
                }
              } else {
                for (var j = 1; j <= moment().month(); j++) {
                  mesesSelect.push(<option value={j}>{meses[j]}</option>);
                }
              }
              return mesesSelect;
            })()}
          </select>
        </Col> */}
        <Col
          xs={12}
          sm={1}
          className="d-flex flex-column justify-content-center align-items-center d-sm-block"
        >
          <button
            className="btn-busca"
            type="submit"
            onClick={buscarComprasPontuacao}
          >
            {buscando ? (
              <i className="fas fa-circle-notch fa-spin"></i>
            ) : (
              <i className="fas fa-search"></i>
            )}
          </button>
        </Col>
      </Row>
      <div>
        <ToolkitProvider
          bootstrap4
          keyField="id"
          exportCSV
          data={data}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div className="d-flex flex-row-reverse">
                <ExportCSV {...props.csvProps} data={dataFormated} />
                <SearchBar
                  {...props.searchProps}
                  placeholder="Buscar"
                  className="input-theme"
                />
              </div>
              <BootstrapTable
                noDataIndication={() =>
                  buscando ? (
                    <span>
                      Carregando
                      <i className="fas fa-circle-notch fa-spin ml-3"></i>
                    </span>
                  ) : (
                    <span>Sem resultados</span>
                  )
                }
                classes="table-dark text-center rounded"
                wrapperClasses="rounded"
                bordered={false}
                pagination={paginationFactory(options)}
                {...props.baseProps}
                defaultSorted={defaultSorted}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
      <ModalEstoque
        show={mostrarModalEstoque}
        onHide={fecharModalEstoque}
        id={id}
        codProduto={codProduto}
        rede={rede}
        qtdPontuada={qtdPontuada}
        qtdUltimoPedido={qtdUltimoPedido}
        qtdPenultimoPedido={qtdPenultimoPedido}
        atualizarEstoque={atualizarEstoque}
      />
    </Container>
  );
}
