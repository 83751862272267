import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container } from 'react-bootstrap';
import api from '../../../services/API';
import historico from '../../../services/Historico';
import ModalEncerrarPontuacao from '../ModalEncerrarPontuacao';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import moment from 'moment-timezone';

const { SearchBar } = Search;

export default function PontuacoesPendente() {
  const [data, setData] = useState([]);
  const [buscando, setBuscando] = useState(false);
  const [mostrarModalEncerrarPontuacao, setMostrarModalEncerrarPontuacao] =
    useState(false);
  const [pontuacao, setPontuacao] = useState('');

  useEffect(() => {
    buscarPontuacoes();
  }, []);

  const columns = [
    {
      text: 'Vendedor Loja',
      dataField: 'parceiro',
      sort: true,
    },
    {
      text: 'CPF',
      dataField: 'cpf',
      formatter: (celula, valor) => {
        let cpf = valor.cpf.replace(/[^\d]/g, '');
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      },
    },
    {
      text: 'Código Loja',
      dataField: 'codRevenda',
      sort: true,
    },
    {
      text: 'Loja',
      dataField: 'revenda',
      sort: true,
    },
    {
      text: 'Data do Envio',
      dataField: 'criadoEm',
      formatter: (celula, valor) => {
        return moment(valor.criadoEm).format('DD/MM/YYYY HH:mm:ss');
      },
      sort: true,
    },
    {
      text: 'Processar Pontuação',
      dataField: '',
      formatter: (celula, valor) => {
        return (
          <button
            className="btn-limpo"
            onClick={(e) =>
              processarPontuacao(valor.cpf, valor.diretorioArquivo, valor.id)
            }
          >
            <i className="fas fa-check f-green"></i>
          </button>
        );
      },
    },
    {
      text: 'Encerrar Pontuação',
      dataField: 'id',
      formatter: (celula, valor) => {
        return (
          <button
            onClick={() => {
              visualizarModalEncerrarPontuacao(valor.id);
            }}
            className="btn-limpo"
          >
            <i className="fas fa-times f-red"></i>
          </button>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: 'criadoEm',
      order: 'desc',
    },
  ];

  const buscarPontuacoes = async () => {
    setBuscando(true);
    const resultado = await api.get('/api/pontuacoes/pendentes');
    if (resultado) {
      setData(resultado.data);
    }
    setBuscando(false);
  };

  const processarPontuacao = (cpf, diretorioArquivo, id) => {
    historico.push('/painel/processar/nova-venda', {
      cpf,
      diretorioArquivo,
      id
    });
  };

  const visualizarModalEncerrarPontuacao = (id) => {
    setPontuacao(id);
    setMostrarModalEncerrarPontuacao(true);
  };

  const fecharModalEncerrarPontuacao = () => {
    setMostrarModalEncerrarPontuacao(false);
  };

  useEffect(() => {
    if (buscando) {
      setData([]);
    }
  }, [buscando]);

  const options = {
    sizePerPage: 10,
    showTotal: true,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
      {
        text: 'Todos',
        value: data.length,
      },
    ],
    paginationTotalRenderer: (from, to, size) =>
      size > 0 ? (
        <span className="react-bootstrap-table-pagination-total px-2">
          {from}-{to} de {size} resultados
        </span>
      ) : (
        <span className="react-bootstrap-table-pagination-total px-2">
          Nenhum resultado
        </span>
      ),
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Pontuações Pendentes</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <div>
        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={data}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div className="d-flex flex-row-reverse">
                <SearchBar
                  {...props.searchProps}
                  placeholder="Buscar"
                  className="input-theme mr-2"
                />
              </div>
              <BootstrapTable
                noDataIndication={() =>
                  buscando ? (
                    <span>
                      Carregando
                      <i className="fas fa-circle-notch fa-spin ml-3"></i>
                    </span>
                  ) : (
                    <span>Sem resultados</span>
                  )
                }
                classes="table-dark text-center rounded"
                wrapperClasses="rounded"
                bordered={false}
                pagination={paginationFactory(options)}
                defaultSorted={defaultSorted}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
      <ModalEncerrarPontuacao
        show={mostrarModalEncerrarPontuacao}
        onHide={fecharModalEncerrarPontuacao}
        pontuacao={pontuacao}
      />
    </Container>
  );
}
