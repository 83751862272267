import React from 'react';
import { Container, Row } from 'react-bootstrap';
import Header from '../../../components/Loja/Header';
import Footer from '../../../components/Loja/Footer';

export default function Privacidade() {
  return (
    <>
      <Header auto />

      <Container className="mt-5 mb-5">
        <Row className="flex-column align-items-center justify-content-center mt-5">
          <img
            className="w-100 form-logo"
            src="/imagens/logo-american-points.png"
            alt="Logo American Points"
          ></img>
        </Row>
        <div class="text-justify">
          <div class="mt-5 mb-5">
            <h4 class="f-black text-center font-weight-bold mb-4">
              POLÍTICA DE PRIVACIDADE DA SMART TECNOLOGIA EM VENDAS S/A
            </h4>
            <p>
              Por favor, leia esta Política de Privacidade (“Política”)
              cuidadosamente para entender nossas políticas e práticas sobre
              seus Dados Pessoais (“Dados”) e como os trataremos.
            </p>
            <p>
              Esta Política se aplica a indivíduos que se cadastram no Clube de
              Vantagens American Points (“você”).
            </p>
            <p>
              Esta Política explica como seus Dados Pessoais são coletados,
              usados e divulgados pelo American Points (“Programa”).{' '}
            </p>
            <p>
              Ela também informa como você pode acessar e atualizar seus Dados
              Pessoais e tomar certas decisões sobre como seus Dados Pessoais
              são utilizados.
            </p>
            <p>
              O participante, ao se cadastrar no programa, aceita e concorda com
              os termos abaixo.
            </p>
          </div>
          <br />
          <div>
            <ol>
              <li class="font-weight-bold f-18 mb-3">
                <span>Como coletamos informações pessoais</span>
                <li class="mt-2">
                  <p class="font-weight-normal f-16">
                    Quando você se cadastra no American Points, nós da Smart
                    Tecnologia em Vendas S/A, empresa desenvolvedora e que
                    executa o Programa, coletamos Dados sobre você para criação
                    de um login e senha (“Acesso ao American Points”).
                  </p>
                  <p class="font-weight-normal f-16">
                    Durante o processo de cadastro, nós solicitamos Dados como
                    seu nome completo, endereço de e-mail, data de nascimento,
                    gênero, CPF, endereço, telefone fixo e/ou celular.
                  </p>
                </li>
              </li>
              <li class="font-weight-bold f-18 mb-3">
                <span>Uso das informações pessoais</span>
                <li class="mt-2">
                  <p class="font-weight-normal f-16">
                    Os dados coletados serão utilizados com a finalidade de
                    administração e operação do Programa, incluindo o uso para
                    os fins de:
                  </p>
                  <ul class="font-weight-normal f-16 ml-5">
                    <li>
                      Cadastro junto ao Programa e contato com os membros
                      cadastrados;
                    </li>
                    <li>Acúmulo de PONTOS;</li>
                    <li>Entrega de premiações;</li>
                    <li>Otimização do catálogo de prêmios;</li>
                    <li>
                      Desenvolvimento de inteligência de mercado, parâmetros e
                      perfil de consumo, bem como outras atividades associadas
                      ao bom funcionamento e desenvolvimento do PROGRAMA;
                    </li>
                    <li>
                      Relatórios, estudos ou indicadores de mercado e sua
                      respectiva disponibilização única e exclusivamente a American Burrs.
                    </li>
                  </ul>
                </li>
              </li>
              <li class="font-weight-bold f-18 mb-3">
                <span>Compartilhamento de informações pessoais</span>
                <li class="mt-2">
                  <p class="font-weight-normal f-16">
                    O Programa não divulga e não compartilha em hipótese alguma,
                    as suas informações pessoais para terceiros, exceto ao Clube
                    de Vantagens American Points.
                  </p>
                  <p class="font-weight-normal f-16">
                    Conforme previsto no Regulamento do Programa, Clube de
                    Vantagens American Points garante que os dados recebidos serão
                    utilizados para análise de mercado e encaminhamento de
                    publicidade sobre produtos da marca American Burrs.{' '}
                  </p>
                  <p class="font-weight-normal f-16">
                    A American Burrs garante que os dados recebidos serão armazenados
                    em ambiente seguro e com acesso restrito somente a
                    empregados da empresa, todos contratualmente obrigados a
                    mantê-los em proteção e sob sigilo. A American Burrs garante
                    também que os dados serão compartilhados com empresas
                    provedoras do serviço de armazenamento e gestão dos dados,
                    todas contratualmente obrigadas a mantê-los em segurança e
                    sob sigilo. Quando muito necessário, a American Burrs poderá
                    compartilhar os dados recebidos com prestadores de serviço,
                    para aprimorar as atividades da empresa, bem como com
                    consultores jurídicos, para ingresso ou defesa em ações
                    administrativas ou judiciais. Todos contratualmente
                    obrigados a manter a confidencialidade das informações.
                    Poderá compartilhar também para o cumprimento de
                    determinação de autoridade judicial ou administrativa
                    competente.
                  </p>
                </li>
              </li>
              <li class="font-weight-bold f-18 mb-3">
                <span>Preservação de informações pessoais</span>
                <li class="mt-2">
                  <p class="font-weight-normal f-16">
                    Não mantemos as suas informações pessoais por mais tempo do
                    que o necessário para os objetivos para os quais são
                    processadas. Se existirem limitações técnicas que impedirem
                    a exclusão ou anonimização, protegemos as informações
                    pessoais e limitamos o uso ativo dessas informações.
                  </p>
                </li>
              </li>
              <li class="font-weight-bold f-18 mb-3">
                <span>Suas escolhas</span>
                <li class="mt-2">
                  <p class="font-weight-normal f-16">
                    Dados Pessoais: Será possível acessar e revisar estas
                    informações pessoais utilizadas na hora do cadastro a
                    qualquer momento no menu “Minha Conta” Após o login.
                  </p>
                  <p class="font-weight-normal f-16">
                    Exclusão de Dados Pessoais: Se desejar cancelar sua
                    participação no programa, você pode enviar um e-mail para{' '}
                    <a href="malito:contato@americanpoints.com.br">
                      contato@americanpoints.com.br{' '}
                    </a>{' '}
                    fazendo a solicitação.
                  </p>
                </li>
              </li>
              <li class="font-weight-bold f-18 mb-3">
                <span>Onde armazenamos seus dados</span>
                <li class="mt-2">
                  <p class="font-weight-normal f-16">
                    Os dados são armazenados em bancos de dados exclusivos do
                    Programa, em ambiente totalmente seguro.
                  </p>
                </li>
              </li>
              <li class="font-weight-bold f-18 mb-3">
                <span>Alterações a esta Política</span>
                <li class="mt-2">
                  <p class="font-weight-normal f-16">
                    Se houver alterações na forma em que tratamos os seus Dados
                    Pessoais, vamos atualizar esta Política. Nos reservamos ao
                    direito de fazer alterações às nossas práticas e a esta
                    Política a qualquer tempo.
                  </p>
                </li>
              </li>
              <li class="font-weight-bold f-18 mb-3">
                <span>Contato</span>
                <li class="mt-2">
                  <p class="font-weight-normal f-16">
                    Para dúvidas ou reclamações sobre o nosso uso das suas
                    informações pessoais ou sobre a nossa Política de
                    privacidade, entre em contato conosco pelo e-mail{' '}
                    <a href="malito:contato@americanpoints.com.br">
                      contato@americanpoints.com.br
                    </a>
                  </p>
                </li>
              </li>
            </ol>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
