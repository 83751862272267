import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import api from '../../../services/API';

export default function ModalLiberacaoMassa({
  show,
  onHide,
  selectedRows,
  atualizarLiberacaoTabela
}) {
  const [quantidade, setQuantidade] = useState('');
  const [comentario, setComentario] = useState('');
  const [arquivo, setArquivo] = useState(null);
  const [loading, setLoading] = useState(false);

  const formatarNumero = (numero) => {
    return Number(numero).toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  };

  useEffect(() => {
    if (show && selectedRows.length > 0) {
      const totalNecessario = selectedRows.reduce((total, row) => {
        return total + (row.qtdAf < 0 ? Math.abs(row.qtdAf * row.pontosUnitario) : 0);
      }, 0);

      setQuantidade(formatarNumero(totalNecessario));
    }
  }, [show, selectedRows]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append('arquivo', arquivo);
    formData.append('comentario', comentario);

    const numeroLimpo = quantidade
      .replace(/\./g, '')
      .replace(',', '.');

    formData.append('quantidade', numeroLimpo);
    formData.append('items', JSON.stringify(selectedRows));

    try {
      const response = await api.post('/api/antifraude/liberacao-massa', formData);
      if (response.status === 200) {
        setQuantidade('');
        setComentario('');
        setArquivo(null);
        onHide();
        atualizarLiberacaoTabela();
      }
    } catch (error) {
      console.error('Erro ao realizar liberação em massa:', error);
    }
    setLoading(false);
  };

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Liberação em Massa</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Quantidade a Liberar</Form.Label>
            <Form.Control
              type="text"
              value={quantidade}
              onChange={(e) => {
                const value = e.target.value.replace(/[^\d.,]/g, '');
                setQuantidade(value);
              }}
              required
            />
            <Form.Text className="text-muted">
              Valor sugerido para zerar todos os saldos negativos
            </Form.Text>
          </Form.Group>
          
          <Form.Group>
            <Form.Label>Comentário</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={comentario}
              onChange={(e) => setComentario(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Comprovante</Form.Label>
            <Form.Control
              type="file"
              onChange={(e) => setArquivo(e.target.files[0])}
              required
            />
          </Form.Group>

          <div className="mt-3">
            <h6>Itens selecionados: {selectedRows.length}</h6>
            <ul>
              {selectedRows.map(row => (
                <li key={`${row.rede}_${row.codProduto}`}>
                  {row.rede} - {row.codProduto} - {row.produto} 
                  {row.saldoPontos < 0 && ` (Saldo Pontos: ${formatarNumero(row.saldoPontos)})`}
                </li>
              ))}
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Cancelar
          </Button>
          <Button variant="primary" type="submit" disabled={loading}>
            {loading ? 'Processando...' : 'Confirmar Liberação'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
} 