import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, ListGroup, Row, Col } from 'react-bootstrap';
import api from '../../../../services/API';
import { toast } from 'react-toastify';
import InputMask from 'react-input-mask';

export default function ModalEditarContatos({ show, onHide, revendaId }) {
  const [contatos, setContatos] = useState([]);
  const [novoContato, setNovoContato] = useState({ nome: '', telefone: '', email: '', cargo: '' });
  const [editando, setEditando] = useState(false);

  useEffect(() => {
    if (show && revendaId) {
      carregarContatos();
    }
  }, [show, revendaId]);

  const carregarContatos = async () => {
    try {
      const response = await api.get(`/api/revendas/${revendaId}/contatos`);
      if (response && response.data) {
        setContatos(response.data);
      }
    } catch (error) {
      console.error('Erro ao carregar contatos:', error);
    }
  };

  const validarCampos = (data) => {
    if (!data.nome) {
      toast.error('Nome é obrigatório', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 5000,
      });
      return false;
    }
    return true;
  };

  const adicionarContato = async (e) => {
    e.preventDefault();
    if (!validarCampos(novoContato)) return;

    setEditando(true);
    try {
      await api.post(`/api/revendas/${revendaId}/contatos`, novoContato);
      setNovoContato({ nome: '', telefone: '', email: '', cargo: '' });
      carregarContatos();
      toast.success('Contato adicionado com sucesso', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 5000,
      });
    } catch (error) {
      console.error('Erro ao adicionar contato:', error);
      toast.error('Erro ao adicionar contato', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 5000,
      });
    }
    setEditando(false);
  };

  const excluirContato = async (contatoId) => {
    try {
      await api.delete(`/api/revendas/${revendaId}/contatos/${contatoId}`);
      carregarContatos();
      toast.success('Contato excluído com sucesso', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 5000,
      });
    } catch (error) {
      console.error('Erro ao excluir contato:', error);
      toast.error('Erro ao excluir contato', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 5000,
      });
    }
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered scrollable>
      <Modal.Header closeButton>
        <Modal.Title>Editar Contatos</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={adicionarContato}>
          <Row>
            <Col xs={12} sm={6}>
              <Form.Group>
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  type="text"
                  value={novoContato.nome}
                  onChange={(e) => setNovoContato({ ...novoContato, nome: e.target.value })}
                  required
                />
              </Form.Group>
            </Col>
            <Col xs={12} sm={6}>
              <Form.Group>
                <Form.Label>Telefone</Form.Label>
                <InputMask
                  mask="(99) 99999-9999"
                  value={novoContato.telefone}
                  onChange={(e) => setNovoContato({ ...novoContato, telefone: e.target.value })}
                >
                  {(inputProps) => <Form.Control {...inputProps} type="text" />}
                </InputMask>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={6}>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  value={novoContato.email}
                  onChange={(e) => setNovoContato({ ...novoContato, email: e.target.value })}
                />
              </Form.Group>
            </Col>
            <Col xs={12} sm={6}>
              <Form.Group>
                <Form.Label>Cargo</Form.Label>
                <Form.Control
                  type="text"
                  value={novoContato.cargo}
                  onChange={(e) => setNovoContato({ ...novoContato, cargo: e.target.value })}
                />
              </Form.Group>
            </Col>
          </Row>
          <Button variant="danger" type="submit" disabled={editando}>
            {editando ? 'Adicionando...' : 'Adicionar Contato'}
          </Button>
        </Form>
        <ListGroup className="mt-3">
          {contatos.map((contato) => (
            <ListGroup.Item key={contato.id} className="d-flex justify-content-between align-items-center">
              <div>
                <strong>{contato.nome}</strong>
                {contato.telefone && <span> - {contato.telefone}</span>}
                <br />
                {(contato.email || contato.cargo) && (
                  <small>
                    {contato.email && <span>{contato.email}</span>}
                    {contato.email && contato.cargo && <span> | </span>}
                    {contato.cargo && <span>{contato.cargo}</span>}
                  </small>
                )}
              </div>
              <Button variant="danger" size="sm" onClick={() => excluirContato(contato.id)}>
                Excluir
              </Button>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
