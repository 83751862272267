/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container, Row, Col } from 'react-bootstrap';
import api from '../../../services/API';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import moment from 'moment-timezone';
import sortFunc from '../../../utils/ordernarColunaInt';
import { toast } from 'react-toastify';
import { CSVLink } from 'react-csv';

const ExportCSV = (props) => {
  return (
    <div>
      <CSVLink
        data={props.data}
        separator={';'}
        filename={`Historico${moment().format('YYYYMMDDHHmmss')}.csv`}
        headers={[
          { label: 'Rede', key: 'rede' },
          { label: 'Gestor', key: 'gestor' },
          { label: 'Janeiro', key: 'janeiro' },
          { label: 'Fevereiro', key: 'fevereiro' },
          { label: 'Março', key: 'março' },
          { label: 'Abril', key: 'abril' },
          { label: 'Maio', key: 'maio' },
          { label: 'Junho', key: 'junho' },
          { label: 'Julho', key: 'julho' },
          { label: 'Agosto', key: 'agosto' },
          { label: 'Setembro', key: 'setembro' },
          { label: 'Outubro', key: 'outubro' },
          { label: 'Novembro', key: 'novembro' },
          { label: 'Dezembro', key: 'dezembro' },
        ]}
      >
        <button className="btn-primario-slim ml-2">
          <i className="fa fa-file-download px-2"></i>
        </button>
      </CSVLink>
    </div>
  );
};

const { SearchBar } = Search;

export default function Historico() {
  const columns = [
    {
      dataField: 'rede',
      text: 'Rede',
      sort: true,
    },
    {
      dataField: 'gestor',
      text: 'Gestor',
      sort: true,
    },
    {
      dataField: 'janeiro',
      text: 'Janeiro',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.janeiro)
          .toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'fevereiro',
      text: 'Fevereiro',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.fevereiro)
          .toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'marco',
      text: 'Março',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.marco)
          .toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'abril',
      text: 'Abril',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.abril)
          .toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'maio',
      text: 'Maio',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.maio)
          .toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'junho',
      text: 'Junho',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.junho)
          .toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'julho',
      text: 'Julho',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.julho)
          .toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'agosto',
      text: 'Agosto',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.agosto)
          .toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'setembro',
      text: 'Setembro',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.setembro)
          .toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'outubro',
      text: 'Outubro',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.outubro)
          .toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'novembro',
      text: 'Novembro',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.novembro)
          .toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'dezembro',
      text: 'Dezembro',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.dezembro)
          .toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      sortFunc: sortFunc,
    },
  ];

  useEffect(() => {
    buscarRedes();
    buscarPrimeiraData();
    buscarHistorico();
  }, []);

  const buscarRedes = async () => {
    const resultado = await api.get('/api/redes?ativo=1');
    if (resultado) {
      setRedesSelect(resultado.data.redes);
    }
  };

  const buscarHistorico = async () => {
    if (!anoSelecionado) {
      toast.error(`Selecione um período antes`, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }

    setBuscando(true);
    const resultado = await api.get(
      `/api/historico?rede=${redeSelecionada}&ano=${anoSelecionado}`
    );
    if (resultado && resultado.data && resultado.data.historico) {
      setData(resultado.data.historico);
    } else {
      setData([]);
    }
    setBuscando(false);
  };

  const [data, setData] = useState([]);
  const [anoSelecionado, setAnoSelecionado] = useState(moment().year());
  const [dataFormated, setDataFormated] = useState([]);
  const [anos, setAnos] = useState([]);
  const [redeSelecionada, setRedeSelecionada] = useState('');
  const [redesSelect, setRedesSelect] = useState([]);
  const [buscando, setBuscando] = useState(false);

  const buscarPrimeiraData = async () => {
    const resultado = await api.get('/api/historico/primeiraData');
    if (resultado && resultado.data.primeiraData) {
      const anos = [];
      for (
        let i = moment(resultado.data.primeiraData).year();
        i <= moment().year();
        i += 1
      ) {
        anos.push(i);
      }
      setAnos(anos);
    }
  };

  useEffect(() => {
    if (buscando) {
      setData([]);
    }
  }, [buscando]);

  useEffect(() => {
    if (data) {
      setDataFormated(
        data.map((d) => ({
          rede: d.rede,
          gestor: d.gestor || 'Não definido',
          janeiro: Number(d.janeiro)
            .toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })
            .replace(/\u00a0/g, ' '),
          fevereiro: Number(d.fevereiro)
            .toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })
            .replace(/\u00a0/g, ' '),
          março: Number(d.marco)
            .toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })
            .replace(/\u00a0/g, ' '),
          abril: Number(d.abril)
            .toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })
            .replace(/\u00a0/g, ' '),
          maio: Number(d.maio)
            .toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })
            .replace(/\u00a0/g, ' '),
          junho: Number(d.junho)
            .toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })
            .replace(/\u00a0/g, ' '),
          julho: Number(d.julho)
            .toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })
            .replace(/\u00a0/g, ' '),
          agosto: Number(d.agosto)
            .toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })
            .replace(/\u00a0/g, ' '),
          setembro: Number(d.setembro)
            .toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })
            .replace(/\u00a0/g, ' '),
          outubro: Number(d.outubro)
            .toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })
            .replace(/\u00a0/g, ' '),
          novembro: Number(d.novembro)
            .toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })
            .replace(/\u00a0/g, ' '),
          dezembro: Number(d.dezembro)
            .toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })
            .replace(/\u00a0/g, ' '),
        }))
      );
    }
  }, [data]);

  const options = {
    sizePerPage: 10,
    showTotal: true,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
      {
        text: 'Todos',
        value: data.length,
      },
    ],
    paginationTotalRenderer: (from, to, size) =>
      size > 0 ? (
        <span className="react-bootstrap-table-pagination-total px-2">
          {from}-{to} de {size} resultados
        </span>
      ) : (
        <span className="react-bootstrap-table-pagination-total px-2">
          Nenhum resultado
        </span>
      ),
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Histórico</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <Row>
        <Col xs={12} sm={6} md={4} lg={3} className="d-flex flex-column">
          <label htmlFor="redeSelecionada">Selecione uma Rede</label>
          <select
            className="input-select mb-3"
            name="redeSelecionada"
            id="redeSelecionada"
            onChange={(e) => setRedeSelecionada(e.target.value)}
            defaultValue=""
            placeholder=""
          >
            <option value="">Todas</option>
            {redesSelect.map((rede) => (
              <option value={rede.nome} key={rede.nome}>
                {rede.nome}
              </option>
            ))}
          </select>
        </Col>
        <Col xs={12} sm={3} md={3} lg={2} className="d-flex flex-column">
          <label htmlFor="anoSelecionado">Ano</label>
          <select
            className="input-select mb-3"
            name="anoSelecionado"
            id="anoSelecionado"
            onChange={(e) => setAnoSelecionado(e.target.value)}
            value={anoSelecionado}
          >
            <option value="" disabled>
              Selecione um período
            </option>
            {anos.map((ano) => (
              <option value={ano} key={ano}>
                {ano}
              </option>
            ))}
          </select>
        </Col>
        <Col
          xs={12}
          sm={1}
          className="d-flex flex-column justify-content-center align-items-center d-sm-block"
        >
          <button className="btn-busca" type="submit" onClick={buscarHistorico}>
            {buscando ? (
              <i className="fas fa-circle-notch fa-spin"></i>
            ) : (
              <i className="fas fa-search"></i>
            )}
          </button>
        </Col>
      </Row>
      <div>
        <ToolkitProvider
          bootstrap4
          keyField="rede"
          data={data}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div className="d-flex flex-row-reverse">
                <ExportCSV {...props.csvProps} data={dataFormated} />
                <SearchBar
                  {...props.searchProps}
                  placeholder="Buscar"
                  className="input-theme"
                />
              </div>
              <BootstrapTable
                noDataIndication={() =>
                  buscando ? (
                    <span>
                      Carregando
                      <i className="fas fa-circle-notch fa-spin ml-3"></i>
                    </span>
                  ) : (
                    <span>Sem resultados</span>
                  )
                }
                classes="table-dark text-center rounded"
                wrapperClasses="rounded"
                bordered={false}
                pagination={paginationFactory(options)}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    </Container>
  );
}
