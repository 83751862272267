/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container } from 'react-bootstrap';
import api from '../../../services/API';
import ModalItensPontuacao from '../ModalItensPontuacao';
import ModalMidia from '../ModalMidia';
import ModalCancelarPontuacao from '../ModalCancelarPontuacao';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment-timezone';
import sortFunc from '../../../utils/ordernarColunaInt';

const { SearchBar } = Search;

export default function Pontuacoes() {
  const [buscando, setBuscando] = useState(false);
  const [redesSelect, setRedesSelect] = useState([]);
  const [redeSelecionada, setRedeSelecionada] = useState([]);
  const [dataInicial, setDataInicial] = useState(
    moment().subtract(7, 'days').toISOString().substr(0, 10)
  );
  const [dataFinal, setDataFinal] = useState(
    moment().toISOString().substr(0, 10)
  );
  const [data, setData] = useState([]);
  const [mostrarModalItens, setMostrarModalItens] = useState(false);
  const [produtos, setProdutos] = useState([]);
  const [mostrarModalFoto, setMostrarModalFoto] = useState(false);
  const [arquivo, setArquivo] = useState([]);
  const [tipo, setTipo] = useState([]);
  const [mostrarModalCancelarPontuacao, setMostrarModalCancelarPontuacao] =
    useState(false);
  const [pontuacao, setPontuacao] = useState('');

  useEffect(() => {
    buscarRedes();
    buscarPontuacoes();
  }, []);

  const buscarRedes = async () => {
    const resultado = await api.get('/api/redes?ativo=1');
    if (resultado) {
      setRedesSelect(resultado.data.redes);
    }
  };

  useEffect(() => {
    if (buscando) {
      setData([]);
    }
  }, [buscando]);

  const columns = [
    { text: 'ID', dataField: 'id', sort: true },
    {
      text: 'Vendedor Loja',
      dataField: 'parceiro',
      sort: true,
    },
    {
      text: 'Loja',
      dataField: 'revenda',
      sort: true,
    },
    {
      text: 'Cidade',
      dataField: 'cidade',
      sort: true,
    },
    {
      text: 'Gestor',
      dataField: 'gestor',
      sort: true,
    },
    {
      text: 'Nº Fiscal',
      dataField: 'identificador',
      sort: true,
      sortFunc: sortFunc,
    },
    {
      text: 'Pontos',
      dataField: 'totalPontos',
      formatter: (celula, valor) => {
        return Number(valor.totalPontos).toLocaleString('pt-BR');
      },
      sort: true,
      sortFunc: sortFunc,
    },
    // {
    //   text: 'Valor Custo',
    //   dataField: 'valorCusto',
    //   formatter: (celula, valor) => {
    //     return Number(valor.valorCusto)
    //       .toLocaleString('pt-BR', {
    //         style: 'currency',
    //         currency: 'BRL',
    //       })
    //       .toString()
    //       .replace(/\u00a0/g, ' ');
    //   },
    //   sort: true,
    //   sortFunc: sortFunc,
    // },
    {
      text: 'Data da Venda',
      dataField: 'realizadoEm',
      formatter: (celula, valor) => {
        return moment(valor.realizadoEm).format('DD/MM/YYYY');
      },
      sort: true,
    },
    {
      text: 'Data da Pontuação',
      dataField: 'pontuadoEm',
      formatter: (celula, valor) => {
        return moment(valor.pontuadoEm).format('DD/MM/YYYY HH:mm:ss');
      },
      sort: true,
    },
    {
      text: 'Validade dos Pontos',
      dataField: 'venceEm',
      formatter: (celula, valor) => {
        return moment(valor.venceEm).format('DD/MM/YYYY HH:mm:ss');
      },
      sort: true,
    },
    {
      text: 'Itens da Pontuação',
      dataField: 'itens',
      formatter: (celula, valor) => {
        return (
          <button
            className="btn-limpo f-12 p-1"
            onClick={() => visualizarModalItens(valor.itens)}
          >
            <i className="fas fa-list"></i>
          </button>
        );
      },
    },
    {
      text: 'Foto da Venda',
      dataField: 'dirFoto',
      formatter: (celula, valor) => {
        if (valor.diretorioArquivo && valor.diretorioArquivo.endsWith('.pdf')) {
          return (
            <a
              className="a-clean"
              href={`${process.env.REACT_APP_API_BASE_URL}/arquivos/${valor.diretorioArquivo}`}
            >
              <button className="btn-limpo f-12 p-1">
                <i className="fas fa-file"></i>
              </button>
            </a>
          );
        } else if (
          valor.diretorioArquivo &&
          !valor.diretorioArquivo.endsWith('.pdf')
        ) {
          return (
            <button
              className="btn-limpo f-12 p-1"
              onClick={() => {
                visualizarModalFoto(valor.diretorioArquivo, valor.tipo);
              }}
            >
              <i className="fas fa-image"></i>
            </button>
          );
        } else {
          return (
            <button className="btn-limpo f-12 p-1" disabled>
              SEM FOTO
            </button>
          );
        }
      },
    },
    {
      text: 'Cancelar Pontuação',
      dataField: 'id',
      formatter: (celula, valor) => {
        return (
          <button
            className="btn-limpo f-12 p-1"
            onClick={() => {
              visualizarModalCancelarPontuacao(valor.id);
            }}
          >
            <i className="fas fa-times f-red"></i>
          </button>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: 'pontuadoEm',
      order: 'desc',
    },
  ];

  const buscarPontuacoes = async () => {
    setBuscando(true);
    const resultado = await api.get(
      `/api/pontuacoes?rede=${redeSelecionada}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`
    );
    if (resultado) {
      setData(resultado.data.pontuacoes);
    }
    setBuscando(false);
  };

  const visualizarModalItens = (produtos) => {
    setProdutos(produtos);
    setMostrarModalItens(true);
  };

  const fecharModalItens = () => {
    setMostrarModalItens(false);
  };

  const visualizarModalFoto = (dirFoto, tipo) => {
    setArquivo(dirFoto);
    setTipo(tipo);
    setMostrarModalFoto(true);
  };

  const fecharModalFoto = () => {
    setMostrarModalFoto(false);
  };

  const visualizarModalCancelarPontuacao = (id) => {
    setPontuacao(id);
    setMostrarModalCancelarPontuacao(true);
  };

  const fecharModalCancelarPontuacao = () => {
    setMostrarModalCancelarPontuacao(false);
  };

  const options = {
    sizePerPage: 10,
    showTotal: true,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
      {
        text: 'Todos',
        value: data.length,
      },
    ],
    paginationTotalRenderer: (from, to, size) =>
      size > 0 ? (
        <span className="react-bootstrap-table-pagination-total px-2">
          {from}-{to} de {size} resultados
        </span>
      ) : (
        <span className="react-bootstrap-table-pagination-total px-2">
          Nenhum resultado
        </span>
      ),
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Pontuações</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <Row>
        <Col xs={12} sm={6} md={4} lg={3} className="d-flex flex-column">
          <label htmlFor="redeSelecionada">Selecione uma Rede</label>
          <select
            className="input-select mb-3"
            name="redeSelecionada"
            id="redeSelecionada"
            onChange={(e) => setRedeSelecionada(e.target.value)}
            defaultValue=""
            placeholder=""
          >
            <option value="">Todas</option>
            {redesSelect.map((rede) => (
              <option value={rede.nome} key={rede.nome}>
                {rede.nome}
              </option>
            ))}
          </select>
        </Col>
        <Col xs={12} sm={3} md={3} lg={2} className="d-flex flex-column">
          <label>De</label>
          <input
            type="date"
            className="input-theme mb-3"
            value={dataInicial}
            onChange={(e) => setDataInicial(e.target.value)}
            max="2999-12-31"
          ></input>
        </Col>
        <Col xs={12} sm={3} md={3} lg={2} className="d-flex flex-column">
          <label>Até</label>
          <input
            type="date"
            className="input-theme mb-3"
            onChange={(e) => setDataFinal(e.target.value)}
            value={dataFinal}
            max="2999-12-31"
          ></input>
        </Col>
        <Col
          xs={12}
          sm={1}
          className="d-flex flex-column justify-content-center align-items-center d-sm-block"
        >
          <button
            className="btn-busca"
            type="submit"
            onClick={buscarPontuacoes}
          >
            {buscando ? (
              <i className="fas fa-circle-notch fa-spin"></i>
            ) : (
              <i className="fas fa-search"></i>
            )}
          </button>
        </Col>
      </Row>
      <div>
        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={data}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div className="d-flex flex-row-reverse">
                <SearchBar
                  {...props.searchProps}
                  placeholder="Buscar"
                  className="input-theme mr-2"
                />
              </div>
              <BootstrapTable
                classes="table-dark text-center rounded"
                wrapperClasses="rounded"
                bordered={false}
                pagination={paginationFactory(options)}
                defaultSorted={defaultSorted}
                noDataIndication={() =>
                  buscando ? (
                    <span>
                      Carregando
                      <i className="fas fa-circle-notch fa-spin ml-3"></i>
                    </span>
                  ) : (
                    <span>Sem resultados</span>
                  )
                }
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
      <ModalItensPontuacao
        show={mostrarModalItens}
        onHide={fecharModalItens}
        produtos={produtos}
      />
      <ModalMidia
        show={mostrarModalFoto}
        onHide={fecharModalFoto}
        diretorioArquivo={arquivo}
        tipo={tipo}
      />
      <ModalCancelarPontuacao
        show={mostrarModalCancelarPontuacao}
        onHide={fecharModalCancelarPontuacao}
        pontuacao={pontuacao}
      />
    </Container>
  );
}
