/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, ListGroup } from 'react-bootstrap';
import api from '../../../services/API';

export default function ModalLiberacaoAntifraude({
  show,
  onHide,
  rede,
  liberado,
  codProduto,
  atualizarLiberacaoTabela,
}) {
  const [quantidade, setQuantidade] = useState('');
  const [loading, setLoading] = useState(false);
  const [historico, setHistorico] = useState([]);

  useEffect(() => {
    if (show && rede && codProduto) {
      buscarHistorico();
    }
  }, [show, rede, codProduto]);

  const buscarHistorico = async () => {
    try {
      const response = await api.get(`/api/antifraude/historico/${rede}/${codProduto}`);
      if (response.data && response.data.historico) {
        const historicoLiberacoes = response.data.historico.filter(
          item => item.tipo === 'liberouQuantidadeAntifraude'
        );
        setHistorico(historicoLiberacoes);
      }
    } catch (error) {
      console.error('Erro ao buscar histórico:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await api.post('/api/antifraude/liberacao', {
        codProduto,
        rede,
        quantidade,
      });

      if (response.status === 200) {
        atualizarLiberacaoTabela(quantidade, [rede], [codProduto]);
        
        await buscarHistorico();
        
        setQuantidade('');
        
        onHide();
      }
    } catch (error) {
      console.error('Erro ao realizar liberação:', error);
    }
    setLoading(false);
  };

  const renderArquivo = (arquivo) => {
    if (!arquivo) return null;
    
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const url = `${baseUrl}/arquivos/antifraude/${arquivo}`;
    
    return (
      <a 
        href={url} 
        target="_blank" 
        rel="noopener noreferrer" 
        className="text-primary ml-2"
      >
        <i className="fas fa-file-alt"></i> Ver comprovante
      </a>
    );
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Liberação Antifraude</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body className="p-2">
          <Form.Group>
            <Form.Label>Quantidade a Liberar</Form.Label>
            <Form.Control
              type="number"
              step="0.01"
              min="0.01"
              value={quantidade}
              onChange={(e) => setQuantidade(e.target.value)}
              placeholder="0.00"
              required
            />
          </Form.Group>

          {historico.length > 0 && (
            <div className="mt-4">
              <h6>Histórico de Liberações Recentes</h6>
              <ListGroup>
                {historico.map((item, index) => (
                  <ListGroup.Item key={index} className="small">
                    <div className="d-flex justify-content-between align-items-center">
                      <span>
                        <strong>{item.usuario || 'Sistema'}:</strong>{' '}
                        {item.tipo === 'liberouQuantidadeAntifraude' 
                          ? `Liberou ${item.quantidade} unidades` 
                          : item.comentario}
                        {item.arquivo && renderArquivo(item.arquivo)}
                      </span>
                      <small className="text-muted">
                        {new Date(item.data).toLocaleString()}
                      </small>
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Cancelar
          </Button>
          <Button variant="primary" type="submit" disabled={loading}>
            {loading ? 'Processando...' : 'Confirmar Liberação'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
