import { Col, Container, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';

import InputMask from 'react-input-mask';
import api from '../../../services/API';
import axios from 'axios';
import { initialState } from './state';
import { toast } from 'react-toastify';
import { useForm } from '../../../hooks/useForm';
import { useSelector } from 'react-redux';

export default function CadastrarRevenda() {
  const { cpf: cpfUsuario } = useSelector((state) => {
    return state.usuario;
  });

  const { state, setState, resetAllState } = useForm({
    initialState,
  });

  useEffect(() => {
    carregarRedes();
  }, []);

  const validarTelefoneCelular = async (e) => {
    const valor = e.target.value.replace(/[^\d]/g, '');
    if (!valor) return;
    if (valor.length < 10) {
      toast.error(`${e.target.placeholder} inválido`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      e.target.placeholder === 'Telefone'
        ? setState('telefone', '')
        : setState('celular', '');
    }
  };

  const validarCampos = (data) => {
    const entries = Object.entries(data);
    let temNulo = false;
    entries.forEach((param) => {
      if (temNulo) return;
      if (param[1] === '' && param[0] === 'tipoPontuacao') {
        toast.error(`Tipo de pontuação obrigatório`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        temNulo = true;
        return;
      }
      if (param[1] === '' && param[0] === 'rede') {
        toast.error(`Rede obrigatório`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        temNulo = true;
        return;
      }
      if (
        param[1] === '' &&
        param[0] !== 'endComplemento' &&
        param[0] !== 'telefone'
      ) {
        const elemento = document.getElementsByName(param[0])[0];
        toast.error(`${elemento.placeholder} obrigatório`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        temNulo = true;
        return;
      }
    });

    return temNulo;
  };

  const carregarRedes = async () => {
    const resultado = await api.get(
      `/api/redes?ativo=1&cpfUsuario=${cpfUsuario}`
    );
    if (resultado) {
      setState('redes', resultado.data.redes);
    }
  };

  const buscarCep = async (element) => {
    let input = element.target;
    let cep = input.value.replace(/[^\d]/g, '');
    if (!cep) return;
    const resultado = await axios.get(`https://viacep.com.br/ws/${cep}/json`);
    if (!resultado || resultado.data.erro) {
      toast.error('CEP inválido', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setState('endCep', '');
    } else {
      setState('endRua', resultado.data.logradouro);
      setState('endBairro', resultado.data.bairro);
      setState('endCidade', resultado.data.localidade);
      setState('endEstado', resultado.data.uf);
      setState('endComplemento', resultado.data.complemento);
    }
  };

  const buscarCodigo = async (element) => {
    let input = element.target;
    let codigo = input.value;

    await validarCodigo(codigo);
    await setDadosDaStore(codigo);
  };

  const validarCodigo = async (codigo) => {
    if (!codigo) return;

    const resultado = await api.get(`/api/revendas?codigo=${codigo}`);
    if (resultado.data.revendas.length > 0) {
      toast.error('Código em uso por outra Loja', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setState('codigo', '');
      return;
    }
  };

  const setDadosDaStore = async (codigo) => {
    if (!codigo) {
      resetAllState();
      return;
    }

    const dadosDaBase = await api.get(`/api/base_store?codigo=${codigo}`);
    if (dadosDaBase.data.store.length === 0) {
      toast.error('Loja não cadastrada na base', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    const store = dadosDaBase.data.store[0];

    for (const element of Object.keys(store)) {
      if (state.hasOwnProperty(element) && setState && store[element] !== '') {
        setState(element, store[element]);
      }
    }
  };

  const buscarCnpj = async (element) => {
    let input = element.target;
    let cnpj = input.value.replace(/[^\d]/g, '');
    if (!cnpj) return;
    const resultado = await api.get(`/api/revendas?cnpj=${cnpj}`);
    if (resultado.data.revendas.length > 0) {
      toast.error('CNPJ já cadastrado', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setState('cnpj', '');
    }
  };

  const cadastrarRevenda = async (e) => {
    e.preventDefault();
    const data = {
      ...state,
      enviando: undefined,
      redes: undefined,
      rede: state.idRede,
    };

    const temNulo = validarCampos(data);
    if (temNulo) {
      return;
    }

    data.endCep = data.endCep.replace(/[^\d]/g, '');
    data.cnpj = data.cnpj.replace(/[^\d]/g, '');
    data.telefone = data.telefone.replace(/[^\d]/g, '');
    data.celular = data.celular.replace(/[^\d]/g, '');

    setState('enviando', true);
    const resultado = await api.post('/api/revendas', data);

    if (resultado) {
      toast.success('Loja cadastrada com sucesso', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      resetAllState();
    }
    setState('enviando', false);
  };

  return (
    <Container fluid>
      <div>
        <h4 className="pt-3 pb-3 f-black">Cadastrar Loja</h4>
        <hr className="hr-loja mt-0 mb-5" />
      </div>
      <div className="d-flex flex-column align-items-center">
        <form className="form" onSubmit={cadastrarRevenda}>
          <label htmlFor="codigo">Selecione a Rede</label>
          <select
            id="rede"
            name="rede"
            value={state.idRede || ''}
            onChange={(e) => setState('idRede', e.target.value)}
            placeholder="Rede"
          >
            <option disabled value="">
              Selecione a Rede
            </option>
            {state.redes.map((rede) => (
              <option key={rede.id} value={rede.id}>
                {rede.nome}
              </option>
            ))}
          </select>
          <Row>
            <Col className="d-flex flex-column" xs={12} sm={4}>
              <label htmlFor="codigo">Código</label>
              <input
                id="codigo"
                name="codigo"
                type="text"
                placeholder="Código"
                onBlur={buscarCodigo}
                value={state.codigo}
                onChange={(e) => setState('codigo', e.target.value)}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={8}>
              <label htmlFor="cnpj">CNPJ</label>
              <InputMask
                id="cnpj"
                name="cnpj"
                type="text"
                placeholder="CNPJ"
                mask="99.999.999/9999-99"
                value={state.cnpj}
                onChange={(e) => setState('cnpj', e.target.value)}
                onBlur={buscarCnpj}
              ></InputMask>
            </Col>
          </Row>

          <label htmlFor="nomeFantasia">Nome Fantasia</label>
          <input
            id="nomeFantasia"
            name="nomeFantasia"
            type="text"
            placeholder="Nome Fantasia"
            value={state.nomeFantasia}
            onChange={(e) => setState('nomeFantasia', e.target.value)}
          ></input>
          <label htmlFor="razaoSocial">Razão Social</label>
          <input
            id="razaoSocial"
            name="razaoSocial"
            type="text"
            placeholder="Razão Social"
            value={state.razaoSocial}
            onChange={(e) => setState('razaoSocial', e.target.value)}
          ></input>
          <Row>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="responsavel">Responsável</label>
              <input
                id="responsavel"
                name="responsavel"
                type="text"
                placeholder="Responsável"
                value={state.responsavel}
                onChange={(e) => setState('responsavel', e.target.value)}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="endCep">CEP</label>
              <InputMask
                id="endCep"
                name="endCep"
                type="text"
                placeholder="CEP"
                mask="99999-999"
                onBlur={buscarCep}
                value={state.endCep}
                onChange={(e) => setState('endCep', e.target.value)}
              ></InputMask>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex flex-column" xs={12} sm={9}>
              <label htmlFor="endRua">Endereço</label>
              <input
                id="endRua"
                name="endRua"
                type="text"
                placeholder="Endereço"
                value={state.endRua}
                onChange={(e) => setState('endRua', e.target.value)}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={3}>
              <label htmlFor="endNumero">Número</label>
              <input
                id="endNumero"
                name="endNumero"
                type="text"
                placeholder="Número"
                value={state.endNumero}
                onChange={(e) => setState('endNumero', e.target.value)}
              ></input>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="endBairro">Bairro</label>
              <input
                id="endBairro"
                name="endBairro"
                type="text"
                placeholder="Bairro"
                value={state.endBairro}
                onChange={(e) => setState('endBairro', e.target.value)}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="endComplemento">Complemento</label>
              <input
                id="endComplemento"
                name="endComplemento"
                type="text"
                placeholder="Complemento"
                value={state.endComplemento}
                onChange={(e) => setState('endComplemento', e.target.value)}
              ></input>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="endCidade">Cidade</label>
              <input
                id="endCidade"
                name="endCidade"
                type="text"
                placeholder="Cidade"
                value={state.endCidade}
                onChange={(e) => setState('endCidade', e.target.value)}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="endEstado">Estado</label>
              <input
                id="endEstado"
                name="endEstado"
                type="text"
                placeholder="Estado"
                value={state.endEstado}
                onChange={(e) => setState('endEstado', e.target.value)}
                maxLength={2}
              ></input>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex flex-column" xs={12}>
              <label htmlFor="email">Email</label>
              <input
                id="email"
                name="email"
                type="text"
                placeholder="Email"
                value={state.email}
                onChange={(e) => setState('email', e.target.value)}
              ></input>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="celular">Celular</label>
              <InputMask
                id="celular"
                name="celular"
                type="text"
                placeholder="Celular"
                value={state.celular}
                mask="(99) 99999-9999"
                onChange={(e) => setState('celular', e.target.value)}
                onBlur={validarTelefoneCelular}
              ></InputMask>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="telefone">Telefone</label>
              <InputMask
                id="telefone"
                name="telefone"
                type="text"
                placeholder="Telefone"
                mask="(99) 9999-9999"
                value={state.telefone}
                onChange={(e) => setState('telefone', e.target.value)}
                onBlur={validarTelefoneCelular}
              ></InputMask>
            </Col>
          </Row>

          <div className="d-flex flex-row justify-content-center align-items-center text-left">
            <label className="mb-0">Tipo de pontuação</label>
            <label className="m-0 p-3 f-16" htmlFor="tipoPontuacaoSistema">
              Sistema
            </label>
            <input
              className="m-0 p-3"
              id="tipoPontuacaoSistema"
              name="tipoPontuacao"
              type="radio"
              value={0}
              onChange={(e) => setState('tipoPontuacao', e.target.value)}
            ></input>
            <label className="m-0 p-3 f-16" htmlFor="tipoPontuacaoSistema">
              Digitação
            </label>
            <input
              className="m-0 p-3"
              id="tipoPontuacaoFoto"
              name="tipoPontuacao"
              type="radio"
              value={1}
              onChange={(e) => setState('tipoPontuacao', e.target.value)}
            ></input>
            <label className="m-0 p-3 f-16" htmlFor="tipoPontuacaoSistema">
              Foto
            </label>
            <input
              className="m-0 p-3"
              id="tipoPontuacaoFoto"
              name="tipoPontuacao"
              type="radio"
              value={2}
              onChange={(e) => setState('tipoPontuacao', e.target.value)}
            ></input>
          </div>
          {state.enviando ? (
            <button disabled className="btn-primario" type="submit">
              CADASTRANDO...
            </button>
          ) : (
            <button className="btn-primario" type="submit">
              CADASTRAR
            </button>
          )}
        </form>
      </div>
    </Container>
  );
}
