import React, { useState, useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import './styles.css';
import { Form, Input } from '@rocketseat/unform';
import { useDispatch, useSelector } from 'react-redux';
import { autenticarParceiro } from '../../../store/modules/autenticacao/actions';
import Footer from '../../../components/Loja/Footer';
import { Link } from 'react-router-dom';

export default function Login() {
  const dispatch = useDispatch();
  const enviando = useSelector((state) => state.autenticacao.autenticandoLoja);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    document.title = 'American Burrs - Login';

    const checkIfMobile = () => {
      setIsMobile(window.matchMedia("(max-width: 767px)").matches);
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  const solicitarAutenticacao = ({ cpf, senha }) => {
    dispatch(autenticarParceiro(cpf, senha));
  };

  return (
    <div style={{ backgroundColor: '#fff', overflowX: 'hidden' }}>
      <div className="banner-container" style={{ width: '100vw', overflow: 'hidden', marginLeft: 'calc(-50vw + 50%)' }}>
        <img
          style={{ width: '100%', maxWidth: 'none' }}
          src={isMobile ? "imagens/banner-mobile.png" : "imagens/banner-login.png"}
          alt="American Points"
        />
      </div>
      <Container>
        <Row className="justify-content-center mt-5 mb-5">
          <Form
            onSubmit={solicitarAutenticacao}
            className="form-login form-login-loja"
          >
            <h5>FAÇA SEU LOGIN</h5>
            <label>Entre com seus dados e acesse sua conta</label>
            <label htmlFor="cpf">CPF</label>
            <Input name="cpf" type="text" placeholder="CPF"></Input>
            <label htmlFor="senha">Senha</label>
            <Input name="senha" type="password" placeholder="Senha"></Input>

            <button disabled={enviando} className="btn-primario" type="submit">
              {enviando ? 'VALIDANDO...' : 'ENTRAR'}
            </button>
            <Link
              className="mb-3 mt-3 text-center link-clean"
              to="/esqueceu-senha"
            >
              Esqueceu sua senha?
            </Link>
          </Form>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}
